import { icons } from 'lucide-react';

interface IconProps {
  name: keyof typeof icons;
  color?: string;
  size?: number;
}

const LucideIcon = ({ name, color, size }: IconProps) => {
  const Icon = icons[name];
  if(!icons[name]) return null;

  return <Icon color={color} size={size ||16} />;
};

export default LucideIcon;
